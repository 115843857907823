<template>
  <component
    :is="component"
    :bank="selectedBank"
    :is-loading="form.loading"
    @back="clearSelectedOption"
    @back-to-main-selection="emitBackEvent"
    @select-option="selectOption"
    @update-bank-account-verification-options="
      emitBankAccountVerificationOptions
    "
    @verify-manual-upload="proceedToNextStep"
    @verify-mbs="proceedToNextStep"
    @verify-mobile="proceedToNextStep"
    @verify-mono="proceedToNextStep"
    @verify-okra="proceedToNextStep"
    @verify-ussd="proceedToNextStep"
  />
</template>

<script>
export default {
  name: 'BankAccountVerification',
  components: {
    ManualUploadForm: () => import('./manual-upload/Form'),
    MbsForm: () => import('./mbs/Index'),
    MobileForm: () => import('./mobile/Form'),
    MonoForm: () => import('./mono/Form'),
    OkraForm: () => import('./okra/Form'),
    Selection: () => import('./Selection'),
    UssdForm: () => import('./ussd/Form')
  },
  data() {
    return {
      form: this.$options.basicForm(['bank', 'verification_option'])
    };
  },
  computed: {
    component() {
      switch (this.verificationOption) {
        case 'manual-upload':
          return 'manual-upload-form';
        case 'mbs':
          return 'mbs-form';
        case 'mobile':
          return 'mobile-form';
        case 'mono':
          return 'mono-form';
        case 'okra':
          return 'okra-form';
        case 'ussd':
          return 'ussd-form';
        default:
          return 'selection';
      }
    },
    selectedBank() {
      return this.form.data.bank.value;
    },
    verificationOption() {
      return this.form.data.verification_option.value;
    }
  },
  methods: {
    clearSelectedOption() {
      this.form.data.verification_option.value = null;
    },
    emitBackEvent() {
      this.$emit('back');
    },
    emitBankAccountVerificationOptions(options = []) {
      this.$emit('update-bank-account-verification-options', options);
    },
    proceedToNextStep() {
      this.$success({
        title: 'Bank Account Verified',
        body: 'Your account is under review and will be active once approved.',
        button: 'Okay',
        onClose: () => {
          this.$emit('complete-bank-account-verification');
        }
      });
    },
    selectOption({ bank, option }) {
      this.form.data.bank.value = bank;
      switch (option) {
        case 'manual-upload':
        case 'mbs':
        case 'mobile':
        case 'mono':
        case 'okra':
        case 'ussd':
        default:
          this.form.data.verification_option.value = option;
          this.$emit('update-bank-account-verification-options', []);
          break;
      }
    }
  }
};
</script>
